import genericConfigAggregator from '@bluheadless/ui/src/particles/rich-content/renderers/magento-pagebuilder/content-types/generic/config-aggregator'

const configAggregator = (node) => {
	const configs = genericConfigAggregator(node)

	let foursixtyConfig = {
		script_url: '',
		script_data: '',
		style: '',
	}

	try {
		const customData = JSON.parse(configs?.customData)
		foursixtyConfig = { ...foursixtyConfig, ...customData }
	} catch (e) {
		console.error(e)
	}

	return {
		...configs,
		foursixtyConfig,
	}
}

export default configAggregator
